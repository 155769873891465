import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Layout from '../components/layout.vue'
import NProgress from '@/utils/progress'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/home',
        name: 'home',
        component: () =>
          import('@/views/Home.vue')
      },
      {
        path: '/templateModel',
        name: 'tempateModel',
        component: () =>
          import('@/views/TemplateModel.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  // start progress bar
  NProgress.start()
  /**
   * 如果是初始哈希路由，跳转到home页面
   */
  if (to.path === '/') {
    next('/home')
  } else {
    next()
  }
  NProgress.done()
})
export default router
