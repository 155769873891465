
import { ref, unref, computed, defineComponent, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router'

export default defineComponent({
  name: 'LayoutCom',

  setup (props, root) {
    const route = useRoute()
    const Component = computed(() => {
      menuSelect(route.path as string)
      return route.path as string
    })
    const menuSelect = (index: string) => {
      console.log(index)
      router.push({
        path: index
      })
    }
    const backHome = () => {
      router.push({
        path: '/home'
      })
    }
    onMounted(() => {
      // router.push({
      //   path: '/home'
      // })
    })

    return {
      Component,
      menuSelect,
      backHome
    }
  }
})

